import { Div } from "../../../base/components/native/div";
import { Img } from "../../../base/components/native/img";
import { EASE } from "../../../base/helpers/style";
import ldb from "../../../base/lib/ldb";
import { IRouteParams } from "../../../base/lib/router";
import { waitFor } from "../../../base/utils/wait";
import images from "../../../configs/images";
import helpers from "../../../helpers";
import services from "../../../services";
import { ActionButton } from "../../shared/action-button";

export const AboutPage = () => {
    const base = Div()
    const title = Div('ABOUT ME')
    const body = Div()
    const contents = Div()
    // const login = Div('Login')
    // const loggedIn = Div()
    // const logout = Div('Logout')
    contents.append(title, body)
    base.append(contents)



    base.cssClass(helpers.styles.PAGE_BASE_STYLE)
    title.cssClass({ margin: '60px 0 0 60px', fontWeight: '100', fontSize: '72px', textTransform: 'uppercase' })
    body.cssClass({ margin: '0 60px 60px', fontWeight: '100', fontSize: '18px' })
    // title.cssClass({ margin: '0 0 21px 0' })
    // login.cssClass({ margin: '0 0 21px 0', cursor: 'pointer' })
    // loggedIn.cssClass({ margin: '0 0 21px 0', fontSize: '16px' })
    // logout.cssClass({ margin: '0 0 21px 0', cursor: 'pointer' })
    contents.cssClass({
        fontSize: '16px',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        textAlign: 'left',
        
    })
    services.about.all().then((data:any) => {
        
        const html = data.contents
        body.html( (marked).parse(html))
    })
    const html = `
        <br/>
        <div class="header">
        <h1>Azadeh Tayebi</h1>
        <p>Born: 1978, Babol, Iran</p>
        </div>
        <div class="section">
        <h2>Education:</h2>
        <div class="content">
            <p>Master of Art Research, University of Alzahra</p>
            <p>Bachelor of Arts in Sculpture, University of Tehran</p>
            <p>Member of the Iranian Sculptors Association</p>
        </div>
        </div>
        <div class="section">
        <h2>Professional Artistic Activities:</h2>
        <div class="content">
            <ul>
                <li>Participated in the Fifth and Sixth Tehran Sculpture Biennials</li>
                <li>Participated in the First, Second, and Fourth Urban Sculpture Biennials</li>
                <li>Designed and installed sculptures in the city of Mashhad</li>
                <li>Participated in the First, Second, and Third Sculpture Expos</li>
                <li>Participated in the Exhibition for Members Under Thirty-Five Years Old of the Iranian Sculptors Association</li>
                <li>Participated in Group Exhibitions at Barg, Laleh, Haftan, Day, and Iranian Artists' House Galleries</li>
                <li>Honored at the First International Festival of Visual Arts of Fajr</li>
            </ul>
        </div>
        </div>
        <div class="section">
        <h2>Skills and Expertise:</h2>
        <div class="content">
            <p>Sculpture: Mastery in various materials including clay, metal, and stone</p>
            <p>Painting: Proficient in acrylic, oil, and watercolor</p>
            <p>Graphic Design: Experienced in digital and print media, specializing in branding and promotional materials</p>
        </div>
        </div>
        Contact me via <a href="mailto:azadehtayebi27@gmail.com">Email</a>
    `

    // body.html(html)

    return {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style(helpers.styles.PAGE_EXIT_STYLE)
            if (to === '/login') base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        },
        async enter({ from = '' }: IRouteParams) {
            await waitFor(200)
            if (from === '/') {
                base.style({...helpers.styles.PAGE_EXIT_STYLE, ...EASE(0)})
            }
            base.style({...helpers.styles.PAGE_ENTER_STYLE, ...EASE(.16)}, 50)
            
            const user = ldb.get('rankr-user')?.profile?.email
            if (user !== undefined && user !== null && user !== '') {
                // login.style({ display: 'none' })
                // loggedIn.style({ display: 'block' })
                // logout.style({ display: 'block' })
                // loggedIn.html(`Logged in as: <br>${user}`)
            } else {
                // login.style({ display: 'block' })
                // loggedIn.style({ display: 'none' })
                // logout.style({ display: 'none' })
            }
        }
    }
};
