import { ABSOLUTE, EASE, HIDE, SHOW, Y } from '../base/helpers/style'
import { CS } from '../base/utils/styler'

export default {
    PAGE_TRANSITION_DURATION: 220,
    PAGE_BASE_STYLE: {
        ...EASE(.16),
        ...HIDE,
        ...Y(60),
        ...ABSOLUTE,
        top: 'env(safe-area-inset-top)',
        zIndex: '9999',
        willChange: 'opacity,transform',
        backgroundColor: 'smoke',
        padding: '0 0',
    },
    PAGE_EXIT_STYLE: {
        ...HIDE,
        ...Y(60)
    },
    PAGE_ENTER_STYLE: {
        ...SHOW,
        ...Y(0)
    },
    PAGE_EXIT_UP_STYLE: {
        ...HIDE,
        ...Y(-60)
    },
    PAGE_EXIT_DESKTOP: {
        ...HIDE,
        ...Y(0)
    },
    PAGE_ENTER_DESKTOP: {
        ...SHOW,
        ...Y(0)
    },
} as {
    PAGE_BASE_STYLE: CS
    PAGE_EXIT_STYLE: CS
    PAGE_ENTER_STYLE: CS
    PAGE_EXIT_UP_STYLE: CS
    PAGE_EXIT_DESKTOP: CS
    PAGE_ENTER_DESKTOP: CS
    PAGE_TRANSITION_DURATION: number
}
