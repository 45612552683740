import app from './app'
import { disableContextMenu, disableTouchStartPassive } from './base/helpers/events'
import db from './services/db'

(async () => {
    await db.init()
    // disableContextMenu({ touch: true, mouse: false })
    disableTouchStartPassive()
    document.body.appendChild(app.el)
})()
