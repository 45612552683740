import { Div } from "../../../base/components/native/div";
import { Img } from "../../../base/components/native/img";
import { IArtwork } from "../../../interfaces/collections";

export const Artwork = (artwork: IArtwork) => {
    const base = Div()

    const title = Div(artwork.title)
    const img = Img(artwork.imageUrl, { width: 130 })
    const desc = Div(artwork.description)
    const price = Div('€ ' + artwork.price)
    base.append(img, title, desc, price)

    title.cssClass({
        fontSize: '16px',
        color: 'gray',
        fontWeight: '100',
    })
    desc.cssClass({
        fontSize: '12px',
        color: 'gray',
        wordSpacing: '2px',
        fontWeight: '100',
    })
    img.cssClass({
        filter: 'drop-shadow(0px 3px 6px #00000088)'
    })

    // on image click open modal with image full size
    img.el.onclick = () => {
        const modal = Div()
        const modalImg = Img(artwork.imageUrl)
        modal.append(modalImg)
        modal.cssClass({
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            padding: '20px',
            backgroundColor: 'rgba(0,0,0,0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
            transition: 'opacity 0.3s',
        })
        modalImg.cssClass({
            maxHeight: '100%',
            maxWidth: '100%',
        })
        const x = Div('✕')
        x.cssClass({
            color: 'white',
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer',
        })
        base.append(modal)
        modal.append(x)
        modal.el.onclick = () => modal.remove()
        x.el.onclick = () => modal.remove()
    }
    
    return base
}