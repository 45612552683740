import { Div } from "../../../base/components/native/div";
import { ICollection } from "../../../interfaces/collections";
import { Artwork } from "./artwork";

export const Collection = (collection: ICollection) => {

    const base = Div()

    const title = Div(collection.title)
    const contents = Div()
    base.append(title, contents)

    collection.artworks.forEach(artwork => {
        contents.append(Artwork(artwork))
    })

    title.cssClass({
        fontSize: '24px',
        margin: '12px 0',
    })

    contents.cssClass({
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
        gap: '20px',
    })

    return base

}