import router, { IRouteParams } from '../../../base/lib/router'
import { Div } from '../../../base/components/native/div'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { Body } from '../../shared/body'
import { Span } from '../../../base/components/native/span'
import { CENTER } from '../../../base/helpers/style'

export const HomePage = () => {

    const base = Div()
    const body = Body()
    const title = Div('Azadeh Tayebi')
    const more = Div('Sculptor and Painter')
    const comingSoon = Div('[ View Collections ]')
    body.append(title, more, comingSoon)
    base.append(body)

    comingSoon.el.onclick = () => router.goto('/collections')
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)
    body.cssClass({
        ...CENTER,
        flexDirection: 'column',
        backgroundImage: 'url(/images/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    })
    title.cssClass({ margin: '60px 0 0', fontWeight: '100', fontSize: '72px', textTransform: 'uppercase', textAlign: 'center' })
    more.cssClass({ margin: '0 0 21px 0',  fontWeight:'100' , fontSize: '28px'})
    comingSoon.cssClass({ margin: '60px 0 21px 0', fontSize: '16px', cursor: 'pointer' })

    function exit() {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }

    return Object.assign(base, { enter, exit })
}