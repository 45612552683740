import { View } from './components/view'
import { HomePage } from './components/pages/home/home-page'
import router from './base/lib/router'
import { Div } from './base/components/native/div'
import configs from './configs'
import { LoginPage } from './components/pages/login/login-page'
import { MenuIcon } from './components/shared/menu-icon'
import { BackIcon } from './components/shared/back-icon'
import { CollectionsPage } from './components/pages/collections/collections-page'
import { AboutPage } from './components/pages/about/about-page'

const view = View()
const app = Div()
const menuIcon = MenuIcon()
const backIcon = BackIcon()
app.append(menuIcon, backIcon, view)

app.cssClass({
    margin: '0 auto',
    transition: 'all .16s',
    overflow: 'hidden',
    position: 'relative',
    [`@media (max-width: ${configs.sizes.MOBILE}px)`]: {
        margin: '0 auto',
    }
})

const routes = {
    '/': HomePage,
    '/about': AboutPage,
    '/collections': CollectionsPage,
    
    // '/clients/:id/edit': AddClientPage,
    // '/clients/:id/transactions': TransactionsPage,
    // '/clients/:id/transactions/add': AddTransactionPage,
    // '/clients/:id/transactions/:transactionId': TransactionPage,
    // '/clients/:id/transactions/:transactionId/edit': AddTransactionPage,

}

router.init({ routes, view })

export default app
