import services from ".";

async function all () {
    try {
        const { data, status } = await services.api.get('/api/collections');
        if (status === 200) {
            return data;
        }
        
    } catch (error) {
        console.log(error);
    }
}

export default {
    all,
}