import { Div } from '../../../base/components/native/div'
import { EASE } from '../../../base/helpers/style'
import ldb from '../../../base/lib/ldb'
import router, { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { ICollection } from '../../../interfaces/collections'
import services from '../../../services'
import { Collection } from './collection'

export const CollectionsPage = () => {

    const base = Div()
    const title = Div('COLLECTIONS')
    const contents = Div()
    contents.append(title)
    base.append(contents)

    base.cssClass(helpers.styles.PAGE_BASE_STYLE)
    title.cssClass({ fontWeight: '700', fontSize: '18px', textTransform: 'uppercase' })

    services.collections.all().then((collections:ICollection[]) => {
        collections.forEach(collection => {
            contents.append(Collection(collection))
        })
        contents.append(Div('<span style="color:gray;margin-top:20px;display:block">To order artworks, feel free to send me an <a href="mailto:azadehtayebi27@gmail.com">email</a></span>'))
    })

    contents.cssClass({
        fontSize: '16px',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '20px 60px',
        height: '100%',
        textAlign: 'left',
    })

    return {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style(helpers.styles.PAGE_EXIT_STYLE)
            if (to === '/login') base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        },
        async enter({ from = '' }: IRouteParams) {
            await waitFor(200)
            if (from === '/') {
                base.style({ ...helpers.styles.PAGE_EXIT_STYLE, ...EASE(0) })
            }
            base.style({ ...helpers.styles.PAGE_ENTER_STYLE, ...EASE(.16) }, 50)
        }
    }
}