import about from './about'
import api from './api'
import collections from './collections'
import db from './db'

export default {
    db,
    api,
    collections,
    about
}