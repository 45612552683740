export default {
    BANNERS: {
        RANK: '/images/rankr-banner.svg',
        BOOKS: '/images/book.jpg',
        TV: '/images/tv.jpg',
        CAMP: '/images/camp.jpg',
        HAPPY_CLIENT: '/images/happy-client.jpg',
        HAPPY_CLIENT_2: '/images/happy-client-2.jpg',
        BEAUTY_SESSION: '/images/beauty-session.jpg',
    },
    ICONS: {
        SCRIBBLE: '/images/scribble.svg',
        EDIT: '/images/edit-icon.svg',
        LOCK: '/images/lock.svg',
        CONFIRM: '/images/confirm-icon.svg',
        DELETE: '/images/delete-icon.svg',
        CANCEL: '/images/cancel-icon.svg',
        ADD: '/images/add-icon.svg',
        DOWNLOAD: '/images/download-icon.svg',
        BACK: '/images/back-icon.svg',
        GOOGLE: '/images/google-icon.svg',
        SPIRAL: '/images/google.svg',
        STAR: '/images/star-icon.svg',
        X: '/images/x-icon.svg',
        DOTS: '/images/dots-icon.svg',
        THUMBS_UP: '/images/thumbs-up-icon.svg',
        THUMBS_DOWN: '/images/thumbs-down-icon.svg',
        ENTER: '/images/enter-icon.svg',
    }
}