import idb from '../base/lib/idb'
import ldb from '../base/lib/ldb'
import { uuidv4 } from '../base/utils/id-generator'

const db = idb('azad-db')
const init = () => {
    // check if initiated
    if (ldb.get('init')) return Promise.resolve(true)
    return new Promise(async (resolve, reject) => {
        const { version } = await db.info()
        await db.createStore('clients', version + 1, { keyPath: 'id', indices: ['_id', 'id', 'at', 'name', 'contact', 'synced'] })
        // await db.createindex('contacts', version + 5, '_id', { unique: true })
        if (!ldb.get('init')) ldb.save('init', true)
        return resolve(true)
    })
}
export default {
    ...db,
    init
}